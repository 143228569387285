export default [
  {
    path: "/candidate/otp",
    name: "auth-otp",
    component: () => import("@/views/auth/Otp.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/setup",
    name: "candidate-setup",
    component: () => import("@/views/auth/SetUpCandidate.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/select-job-type",
    name: "select-job-type",
    component: () => import("@/views/auth/SelectJobType.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/fulltime-form",
    name: "fulltime-form",
    component: () => import("@/views/auth/FulltimeForm.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/paid-consulting-form",
    name: "paid-consulting-form",
    component: () => import("@/views/auth/PaidConsultingForm.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/basic-details",
    name: "auth-basic-details",
    component: () => import("@/views/auth/BasicDetails.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/present-job",
    name: "auth-present-job",
    component: () => import("@/views/auth/PresentJob.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/qualification",
    name: "auth-qualification",
    component: () => import("@/views/auth/Qualification.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/set-profile-picture",
    name: "set-profile-picture",
    component: () => import("@/views/auth/SetProfilePicture.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
  {
    path: "/candidate/client-connect",
    name: "client-connect",
    component: () => import("@/views/auth/ClientConnect.vue"),
    meta: {
      layout: "full",
      authRequired: true,
    },
  },
]
