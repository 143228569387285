export default {
  SAVE_USER_DATA: (state, payload) => {
    state.user = payload
    localStorage.setItem("userData", JSON.stringify(payload))
  },

  SAVE_USER_ACCESS_TOKEN: (state, payload) => {
    state.access_token = payload
    localStorage.setItem("access_token", payload)
  },

  LOGOUT_USER: (state) => {
    state.user = {}
    state.access_token = null
    state.industries = []
    state.candidate_functions = []
    state.sub_functions = []
    state.countries = []
    state.expertises = []
    sessionStorage.clear()
    localStorage.clear()
  },

  SAVE_INDUSTRIES: (state, payload) => {
    state.industries = payload
    // sessionStorage.setItem('INDUSTRIES', JSON.stringify(payload))
  },

  SAVE_CANDIDATE_FUNCTIONS: (state, payload) => {
    state.candidate_functions = payload
    // sessionStorage.setItem('CANDIDATE_FUNCTIONS', JSON.stringify(payload))
  },

  SAVE_SUB_FUNCTIONS: (state, payload) => {
    state.sub_functions = payload
    // sessionStorage.setItem('SUB_FUNCTIONS', JSON.stringify(payload))
  },

  SAVE_COUNTRIES: (state, payload) => {
    state.countries = payload
    // sessionStorage.setItem('COUNTRIES', JSON.stringify(payload))
  },

  SAVE_EXPERTISES: (state, payload) => {
    state.expertises = payload
    // sessionStorage.setItem('COUNTRIES', JSON.stringify(payload))
  },
}
