// eslint-disable-next-line import/no-cycle
import axios from "@/libs/axios"

export default {
  //  page routes

  register: ({ commit }, payload) =>
    axios.post("/register", payload).then(({ data }) => {
      commit("SAVE_USER_DATA", data)

      return data
    }),

  login: ({ commit }, data) => {
    commit("SAVE_USER_DATA", data.user.userData)
    commit("SAVE_USER_ACCESS_TOKEN", data.accessToken)
    return true
  },

  logout: ({ commit }) => {
    commit("LOGOUT_USER")
    return true
  },

  basicDetails: ({ dispatch }, payload) =>
    axios.post("/basic-details", payload).then((res) => {
      if (res.data.status === "success") {
        dispatch("reload_user")
      }

      return res
    }),
  qualificationDetails: ({ dispatch }, payload) =>
    axios.post("/qualification-details", payload).then((res) => {
      if (res.data.status === "success") {
        dispatch("reload_user")
      }

      return res
    }),
  presentJob: ({ dispatch }, payload) =>
    axios.post("/job-oppurnity", payload).then((res) => {
      if (res.data.status === "success") {
        dispatch("reload_user")
      }

      return res
    }),

  selectJobType: ({ dispatch }, payload) =>
    axios.post("/consultation", payload).then((res) => {
      if (res.data.status === "success") {
        dispatch("reload_user")
      }
      return res
    }),

  fullTimeForm: ({ dispatch }, payload) =>
    axios.post("/full-time-job", payload).then((res) => {
      if (res.data.status === "success") {
        dispatch("reload_user")
      }
      return res
    }),
  paidConsultingForm: ({ dispatch }, payload) =>
    axios.post("/consulting", payload).then((res) => {
      if (res.data.status === "success") {
        dispatch("reload_user")
      }
      return res
    }),

  // common routes

  reload_user: ({ commit }) =>
    axios.get("/user").then((response) => {
      commit("SAVE_USER_DATA", response.data)

      return response
    }),

  getIndustries: ({ commit }) =>
    axios.get("/industries").then((res) => {
      commit("SAVE_INDUSTRIES", res.data.industries)
      return res
    }),

  getCandidateFunctions: ({ commit }) =>
    axios.get("/functions").then((res) => {
      commit("SAVE_CANDIDATE_FUNCTIONS", res.data.candidateFunctions)
      return res
    }),

  getSubFunctions: ({ commit }) =>
    axios.get("/sub_functions").then((res) => {
      commit("SAVE_SUB_FUNCTIONS", res.data.sub_functions)
      return res
    }),

  getCountries: ({ commit }) =>
    axios.get("/countries").then((res) => {
      commit("SAVE_COUNTRIES", res.data.countries)
      return res
    }),

  getExpertises: ({ commit }) =>
    axios.get("/expertise").then((res) => {
      commit("SAVE_EXPERTISES", res.data.expertise)
      return res
    }),
}
