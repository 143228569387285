export default {
  user: JSON.parse(localStorage.getItem("userData")),
  access_token: localStorage.getItem("accessToken"),

  // industries: JSON.parse(sessionStorage.getItem('INDUSTRIES')),
  // candidate_functions: JSON.parse(sessionStorage.getItem('CANDIDATE_FUNCTIONS')),
  // sub_functions: JSON.parse(sessionStorage.getItem('SUB_FUNCTIONS')),
  // countries: JSON.parse(sessionStorage.getItem('COUNTRIES')),
  industries: [],
  candidate_functions: [],
  sub_functions: [],
  countries: [],
  expertises: [],
}
